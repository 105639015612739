.r1ntdu9t{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:50px;}@media screen and (width > 640px){.r1ntdu9t{gap:2.5rem;}}
.c57ynia{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;position:relative;}
.c1fcsj73{max-width:648px;-webkit-align-self:center;-ms-flex-item-align:center;-ms-grid-row-align:center;align-self:center;position:relative;}@media screen and (width > 640px){.c1fcsj73{border-radius:0.5rem;box-shadow:0px 0px 14.1px -2px rgba(195, 203, 217, 0.4);padding:1rem;}}
.l4qu78a{position:absolute;inset:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.t1s2m5ij{display:-ms-grid;display:grid;-ms-grid-rows:repeat(1, 1fr);grid-template-rows:repeat(1, 1fr);-ms-grid-auto-flow:column;grid-auto-flow:column;gap:10px;}
.t57pawy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.stat5n7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:20px;}
.s1ng4r68{font-weight:600;font-size:18px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:0.5rem;}
.t1kwvsg2{display:-ms-grid;display:grid;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:1rem;font-weight:600;-ms-grid-rows:auto auto;grid-template-rows:auto auto;-ms-grid-columns:1fr;grid-template-columns:1fr;}.t1kwvsg2 >*{min-width:0;}@media screen and (width > 640px){.t1kwvsg2{-ms-grid-columns:auto 1fr;grid-template-columns:auto 1fr;-ms-grid-auto-rows:auto;grid-auto-rows:auto;}}
.b1pc4t9e{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;}
